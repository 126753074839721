import {AutomatitCarousel} from './automatit_carousel.m';

export const initReviewsCaro = () => {
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#inc_reviews_caro'),
		intervalTiming: false,
		useChevrons: false,
	});
	window.mobileReviewsCaro = AutomatitCarousel({
		element: document.querySelector('#inc_reviews_caro_mobile'),
		intervalTiming: false,
		useChevrons: false,
	});
};