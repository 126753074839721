/* global google */

import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const initHomeCaro = () => {
	const homeCaroImgs = [
		{src: "/static/images/backgrounds/home_hero1.jpg", alt: ""},
		{src: "/static/images/backgrounds/home_hero2.jpg", alt: ""},
		{src: "/static/images/backgrounds/home_hero3.jpg", alt: ""},
		{src: "/static/images/backgrounds/home_hero4.jpg", alt: ""},
		{src: "/static/images/backgrounds/home_hero5.jpg", alt: ""},
		{src: "/static/images/backgrounds/home_hero6.jpg", alt: ""},
	];

	AutomatitCarousel({
		element: document.querySelector('#home_caro'),
		images: homeCaroImgs,
		imagesAsBackgrounds: true,
		showDots: true
	});
};

export const initHomeMap = () => { 
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#map_canvas'),
				locationElementSelector: '#map_canvas',
			});

			theMap.addListener('idle', () => {
				theMap.setZoom(15);
			});
		});
};

export const initHomeFacMap = async () => {
	const tabButtons = document.querySelectorAll('#home_facs_state_selector button');
	const tabs = document.querySelectorAll('.home_state_facs');
	const facs = document.querySelectorAll('.home_fac');

	const mapHelper = getMapsHelper();

	await mapHelper.ready();

	const theMap = mapHelper.createMap({
		element: document.querySelector('#home_fac_map'),
		locationElementSelector: '.home_fac',
		useRichmarker: true,
		markerReducer: el => {
			return {
				lat: el.getAttribute('lat'),
				lng: el.getAttribute('lng'),
				content: `
					<a class="map_pin" state="${el.getAttribute('state')}" href="${el.getAttribute('link')}">
						<img src="/static/images/icons/black_n_yellow_with_shadow.svg" alt="Visit facility" />
						<span>${el.getAttribute('count')}</span>
					</a>
				`
			};
		},
	});

	document.querySelector('#home_facs_state_selector').addEventListener('click', e => {
		const clickedTab = e.target.closest('button');

		if(clickedTab) {
			tabButtons.forEach(i => i.removeAttribute('active'));
			clickedTab.setAttribute('active', 'true');
		}

		tabs.forEach(i => {
			i.removeAttribute('active');
			if(clickedTab.getAttribute('state') === i.getAttribute('state')) {
				i.setAttribute('active', 'true');
			}
		});

		const bounds = new google.maps.LatLngBounds();
		let numMarkers = 0;

		facs.forEach(i => {
			const theMarker = mapHelper.getMarker(i);
			if(theMarker.element.getAttribute('state') === clickedTab.getAttribute('state')) {
				theMarker.marker.setMap(theMap);
				bounds.extend(theMarker.marker.getPosition());
				numMarkers++;
			} else {
				theMarker.marker.setMap(null);
			}
			// theMap.
			// if(clickedTab.getAttribute('state') === i.getAttribute('state')) {
			// 	i.setAttribute('active', 'true');
			// }
		});

		theMap.fitBounds(bounds);

		if(numMarkers === 1) {
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		}

	});

	document.querySelector('#home_facs_state_selector button').click();
};