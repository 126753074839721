import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

export const initFacilityMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/black_n_yellow_with_shadow.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};

export const initFacilityCaro = () => {
	if(window.facilityImgs && window.facilityImgs.length) {
		AutomatitCarousel({
			element: document.querySelector('#facility_caro'),
			images: window.facilityImgs,
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true,
		});
	}
};